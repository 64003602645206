import React, { Component } from 'react';
import logo from "./headinover_temp.png";

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <div class="full">
            <div>
                <img src={logo} class="logo" />
            </div>
            <br />
            <h6 style={{textAlign: "center"}}><a href="mailto:hey@headinover.com">hey@headinover.com</a></h6>
            
        </div>
    );
  }
}
